const cookie = require('js-cookie');

$(() => {
  const modal = $('#newsletter');
  const cookieNewsletter = cookie.get('newsletter');

  if (!cookieNewsletter) {
    if (modal.length) {
      window.setTimeout(function () {
        modal.modal('show');
      }, 8000);

      $(document).on('click', '#newsletter form [type="submit"]', function (e) {

        e.preventDefault();
        var parentForm = $(this).closest('form');
        var url = parentForm.attr('action'),
          serializedData = parentForm.serialize();

        // Chargement ajax
        $.post(url, serializedData, function (data) {
          $('#newsletter form [type="submit"]').addClass('d-none');
          $('#newsletter .alert').removeClass('d-none');

          window.setTimeout(function () {
            modal.modal('hide');
          }, 2000);
        });
      });

      cookie.set('newsletter', 1, { expires: 7 });


      $('.modal-backdrop').click(function() {
        modal.modal('hide');
      });

      $(document).keyup(function (e) {
        if (e.keyCode === 27) {
          modal.modal('hide');
        }
      });
    }
  }
});
